.form-backdrop {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: stretch;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  height: 400px;
  background: white;
  z-index: 2;
}