.header {
  padding: 32px 0;

  display: flex !important;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;
  align-content: stretch;

  h1 {
    color: rgb(30, 41, 59);
    font-weight: 600;
    line-height: 32px;
    font-size: 24px;
  }

  h5 {
  color: rgb(100, 116, 139);
    font-weight: 700;
    line-height: 21px;
    font-size: 14px;
  }

}