.app {
  background-color: #f1f5f9;

  height: 100vh;
  overflow: hidden;

  width: 100vw;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;

  &-content {
    width: 100%;
  }

  .container {
    height: calc(100% - 64px);
    padding-bottom: 24px;
    overflow-y: scroll;
    margin-top: 2px;
  }
}

// Overwrites
.MuiPaper-root {
  box-shadow: 0 0 #0000, 0 0 #0000, 0 1px 3px 0 rgb(0 0 0 / .1), 0 1px 2px -1px rgb(0 0 0 / .1) !important;
}

.MuiCard-root {
  border-radius: 8px;
}

th {
  font-weight: 700 !important;
}