.booking-paid {
  background-color: #8bc34a;
}

.booking-partially-paid {
  background-color: #FFC107;
}

.booking-not-paid {
  background-color: #f44336;
}