.login {
  background: #fff;

  width: 100vw;
  height: 100vh;
  overflow: hidden;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: stretch;

  &--left {
    padding: 48px;

    width: 40vw;
    height: 100vh;

    &-content {
      height: inherit;

      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: flex-end;
      align-content: center;

      .login--input, button {
        margin-bottom: 24px;
        width: 100%;
      }
    }
  }

  &--right {
    padding: 48px;
    background: #0f172a url("https://ni.leicht.io/north_sea_2.8942abc1114d3a8b0fb3079c9b07d91f01be7d85_original.jpg");
    width: 70vw;
    height: 100vh;
  }
}