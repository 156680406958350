.app {
  height: 100vh;
  width: 100vw;
  background-color: #f1f5f9;
  flex-flow: row;
  place-content: stretch flex-start;
  align-items: stretch;
  display: flex;
  overflow: hidden;
}

.app-content {
  width: 100%;
}

.app .container {
  height: calc(100% - 64px);
  margin-top: 2px;
  padding-bottom: 24px;
  overflow-y: scroll;
}

.MuiPaper-root {
  box-shadow: 0 0 #0000, 0 0 #0000, 0 1px 3px #0000001a, 0 1px 2px -1px #0000001a !important;
}

.MuiCard-root {
  border-radius: 8px;
}

th {
  font-weight: 700 !important;
}

.side-menu {
  margin-top: 48px !important;
}

.side-menu--wrapper {
  height: 100vh;
  background: #1e272c;
  flex-flow: column;
  place-content: stretch flex-start;
  align-items: stretch;
  display: flex;
}

.side-menu--wrapper .menu-header {
  flex-flow: column;
  place-content: stretch center;
  align-items: center;
  padding-top: 48px;
  display: flex;
}

.side-menu--wrapper .menu-header p {
  color: #fff;
  margin-top: 24px;
}

.side-menu--wrapper .menu-header--credentials {
  width: 96px;
  height: 96px;
  background: #fff;
  border-radius: 50%;
}

.side-menu--wrapper .menu-header--credentials p {
  color: #0f172a;
  text-align: center;
  margin: 0;
  padding: 0;
  font-size: 24px;
  font-weight: 700;
  line-height: 96px;
}

.side-menu li {
  cursor: pointer;
  margin: 0 10px;
  width: calc(100% - 20px) !important;
}

.side-menu p, .side-menu span {
  color: #9fa2aa;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
}

.side-menu svg {
  color: #9fa2aa;
}

.side-menu--item-active {
  background: #046e65;
  border-radius: 8px;
}

.side-menu--item-active span, .side-menu--item-active p, .side-menu--item-active svg {
  color: #fff;
}

.MuiAppBar-root {
  background: #046e65;
}

.toolbar {
  color: #64748b;
  background: #fff;
}

.header {
  flex-flow: row;
  place-content: stretch space-between;
  align-items: stretch;
  padding: 32px 0;
  display: flex !important;
}

.header h1 {
  color: #1e293b;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
}

.header h5 {
  color: #64748b;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
}

.login {
  width: 100vw;
  height: 100vh;
  background: #fff;
  flex-flow: row;
  place-content: stretch center;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.login--left {
  width: 40vw;
  height: 100vh;
  padding: 48px;
}

.login--left-content {
  height: inherit;
  flex-flow: column;
  place-content: center;
  align-items: flex-end;
  display: flex;
}

.login--left-content .login--input, .login--left-content button {
  width: 100%;
  margin-bottom: 24px;
}

.login--right {
  width: 70vw;
  height: 100vh;
  background: #0f172a url("https://ni.leicht.io/north_sea_2.8942abc1114d3a8b0fb3079c9b07d91f01be7d85_original.jpg");
  padding: 48px;
}

.form-backdrop {
  height: 400px;
  z-index: 2;
  background: #fff;
  flex-flow: row;
  place-content: stretch center;
  align-items: center;
  display: flex;
  inset: 0;
}

.booking-paid {
  background-color: #8bc34a;
}

.booking-partially-paid {
  background-color: #ffc107;
}

.booking-not-paid {
  background-color: #f44336;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("roboto-cyrillic-ext-300-normal.8895cd13.woff2") format("woff2"), url("roboto-all-300-normal.881b4525.woff") format("woff");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("roboto-cyrillic-300-normal.303b9afa.woff2") format("woff2"), url("roboto-all-300-normal.881b4525.woff") format("woff");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("roboto-greek-ext-300-normal.73ef051e.woff2") format("woff2"), url("roboto-all-300-normal.881b4525.woff") format("woff");
  unicode-range: U+1F??;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("roboto-greek-300-normal.962b7627.woff2") format("woff2"), url("roboto-all-300-normal.881b4525.woff") format("woff");
  unicode-range: U+370-3FF;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("roboto-vietnamese-300-normal.6fb53ea7.woff2") format("woff2"), url("roboto-all-300-normal.881b4525.woff") format("woff");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("roboto-latin-ext-300-normal.51d7b0c4.woff2") format("woff2"), url("roboto-all-300-normal.881b4525.woff") format("woff");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("roboto-latin-300-normal.d07a8413.woff2") format("woff2"), url("roboto-all-300-normal.881b4525.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-cyrillic-ext-400-normal.5baf44ce.woff2") format("woff2"), url("roboto-all-400-normal.2cd42759.woff") format("woff");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-cyrillic-400-normal.6d360dd5.woff2") format("woff2"), url("roboto-all-400-normal.2cd42759.woff") format("woff");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-greek-ext-400-normal.3b199b49.woff2") format("woff2"), url("roboto-all-400-normal.2cd42759.woff") format("woff");
  unicode-range: U+1F??;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-greek-400-normal.94705851.woff2") format("woff2"), url("roboto-all-400-normal.2cd42759.woff") format("woff");
  unicode-range: U+370-3FF;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-vietnamese-400-normal.b7170924.woff2") format("woff2"), url("roboto-all-400-normal.2cd42759.woff") format("woff");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-latin-ext-400-normal.65ba6c38.woff2") format("woff2"), url("roboto-all-400-normal.2cd42759.woff") format("woff");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-latin-400-normal.57844c26.woff2") format("woff2"), url("roboto-all-400-normal.2cd42759.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("roboto-cyrillic-ext-500-normal.216ff506.woff2") format("woff2"), url("roboto-all-500-normal.6bf43474.woff") format("woff");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("roboto-cyrillic-500-normal.4c9891c0.woff2") format("woff2"), url("roboto-all-500-normal.6bf43474.woff") format("woff");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("roboto-greek-ext-500-normal.63a0634f.woff2") format("woff2"), url("roboto-all-500-normal.6bf43474.woff") format("woff");
  unicode-range: U+1F??;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("roboto-greek-500-normal.9f44a402.woff2") format("woff2"), url("roboto-all-500-normal.6bf43474.woff") format("woff");
  unicode-range: U+370-3FF;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("roboto-vietnamese-500-normal.11348a9c.woff2") format("woff2"), url("roboto-all-500-normal.6bf43474.woff") format("woff");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("roboto-latin-ext-500-normal.922e2b56.woff2") format("woff2"), url("roboto-all-500-normal.6bf43474.woff") format("woff");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("roboto-latin-500-normal.da1d77f1.woff2") format("woff2"), url("roboto-all-500-normal.6bf43474.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("roboto-cyrillic-ext-700-normal.0921acac.woff2") format("woff2"), url("roboto-all-700-normal.23db685f.woff") format("woff");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("roboto-cyrillic-700-normal.96126479.woff2") format("woff2"), url("roboto-all-700-normal.23db685f.woff") format("woff");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("roboto-greek-ext-700-normal.00d528bb.woff2") format("woff2"), url("roboto-all-700-normal.23db685f.woff") format("woff");
  unicode-range: U+1F??;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("roboto-greek-700-normal.bc3ad99c.woff2") format("woff2"), url("roboto-all-700-normal.23db685f.woff") format("woff");
  unicode-range: U+370-3FF;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("roboto-vietnamese-700-normal.a1447a12.woff2") format("woff2"), url("roboto-all-700-normal.23db685f.woff") format("woff");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("roboto-latin-ext-700-normal.372a1858.woff2") format("woff2"), url("roboto-all-700-normal.23db685f.woff") format("woff");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("roboto-latin-700-normal.0e98339e.woff2") format("woff2"), url("roboto-all-700-normal.23db685f.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/*# sourceMappingURL=index.ffc3b94f.css.map */
